import { TwitterMomentShare } from 'react-twitter-embed';


function App() {
  return (
    <div className="App">
    <header className="App-header">
    </header>
    <body>
      <div style={{display:'flex', flexDirection:'row'}}>
        <TwitterMomentShare momentId={'1333075441779961861'} />
        <TwitterMomentShare momentId={'1352693081741664258'} />
      </div>
    </body>
    </div>
    );
  }
  
  export default App;
  